import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import PaymentComponent from '../../../components/vanliferComponents/PaymentInfo/PaymentComponent';
import TourEditor from '../../../components/vanliferComponents/TourEditor';
import SuggestedTours from '../../../components/vanliferComponents/SuggestedTours/SuggestedTours';
import ErrorHandling from '../../../components/presentationalComponents/ErrorHandling';
import BookingConfirmationComponent from '../../../components/vanliferComponents/BookingConfirmationComponent';
import { TourLoadingSpinner } from './TourLoadingSpinner';
import { createVanliferOverviewStyling } from './VanliferOverviewStyling';
import { fetchAllRegions } from '../../../store/Vanlifer/regionSlice';
import Main from '../../../layouts/Main';
import Divider from '@mui/material/Divider';
import Container from '../../../components/Container';
import SearchToursContainer from '../../../components/vanliferComponents/SearchToursContainer';


function VanliferOverview() {
  const theme = useTheme();
  const vanliferOverviewStyling = createVanliferOverviewStyling(theme);
  const dispatch = useAppDispatch();
  const showPaymentComponent = useAppSelector(state => state.vanliferComponents.showPaymentComponent);
  const showEditorComponent = useAppSelector(state => state.vanliferComponents.showEditorComponent);
  const showBookedComponent = useAppSelector(state => state.vanliferComponents.showBookedComponent);
  const generatedTours = useAppSelector(state => state.tour.generatedTours);
  const previousGeneratedTours = useAppSelector(state => state.tour.previousGeneratedTours);
  const isLoading = useAppSelector(state => state.tour.loading);
  const tourErrorState = useAppSelector(state => state.tour.error);
  const userEntitlements = useAppSelector(state => state.user.entitlements);
  const maxToursPerDay = useMemo( () => {
    return userEntitlements.find(entitlement => entitlement.featureId === 'vanlifermaxdailygeneratedtours')?.value;

  }, [userEntitlements]);

  const lastSixTours = useMemo(() => {
    return previousGeneratedTours?.slice(Math.max(previousGeneratedTours.length - 6, 0));
  }, [previousGeneratedTours]);



  useEffect(() => {
    dispatch(fetchAllRegions());
  }, [dispatch]);

  if (tourErrorState) {
    return <ErrorHandling errorMessage={tourErrorState} />;
  }

  if (showPaymentComponent) {
    return <PaymentComponent />;
  }

  if (showEditorComponent) {
    return <TourEditor />;
  }

  if (showBookedComponent) {
    return <BookingConfirmationComponent />;
  }


  const TourLoading = (): React.ReactElement => (
    <Grid container rowSpacing={2} padding={7}
          columnSpacing={{ xs: 4, sm: 4, md: 6, xl: 8 }}
          justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ ...vanliferOverviewStyling.gridItem }}>
        <Typography variant="h5">Deine Touren sind bald da...</Typography>
      </Grid>
      <Grid item xs={12}>
        <TourLoadingSpinner />
      </Grid>
    </Grid>
  );

  const GenerateToursPrompt = (): React.ReactElement => (
    <Grid my={5} item xs={12} container alignItems="center" alignContent="flex-start">
      <Grid item xs={12} sx={{ ...vanliferOverviewStyling.gridItem }}>
        <Typography variant="h5">Lass uns für dich spannende Reisevorschläge generieren!</Typography>
      </Grid>
    </Grid>
  );

  const NoToursFound = (): React.ReactElement => (
      <>

    <Grid item my={5} xs={12} md={12} container alignItems="center" alignContent="flex-start">
      <Grid item xs={12} sx={{ ...vanliferOverviewStyling.gridItem }}>
        <Box>
          {maxToursPerDay === "Unlimited" && <Typography variant="h5" align="center">Leider haben wir keine Reisevorschläge für Dich gefunden.</Typography>}
          {maxToursPerDay !== "Unlimited" && <Typography variant="h5" align="center">Oder Du hast bereits {maxToursPerDay} Reisevorschläge generiert und damit das tägliche Limit erreicht.</Typography>}
          <Typography variant="h5" align="center">Um weitere Reisevorschläge zu erhalten löse eine "Early Access"-Mitgliedschaft.</Typography>
        </Box>
      </Grid>
      </Grid>
        {previousGeneratedTours && previousGeneratedTours?.length > 0 && (
            <>
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="h6" align="center">
                  Hier sind deine letzten 6 generierten Touren:
                </Typography>
              </Grid>
              <SuggestedTours generatedTours={lastSixTours}/>
            </>
        )}
      </>

  );

  return (
    <Main>
      <Box bgcolor={theme.palette.alternate}>
        <SearchToursContainer />
      </Box>
      <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
      <Box bgcolor={theme.palette.alternate}>
        <Container>
          <div id={'tours'}>
            {isLoading
              ? <TourLoading />
              : !generatedTours
                ? <GenerateToursPrompt />
                : generatedTours?.length === 0 || generatedTours[0].stays?.length === 0
                  ? <NoToursFound />
                  : <SuggestedTours generatedTours={generatedTours}/>
            }
          </div>
        </Container>
      </Box>
    </Main>


    // <React.Fragment>
    //   <Grid item xs={12}>
    //     <Box style={{
    //       backgroundImage: `url(${vanliferHomePicture})`,
    //       height: '100px',
    //       backgroundPosition: 'center 65%',
    //       backgroundSize: 'cover',
    //       width: '100%',
    //       backgroundRepeat: 'no-repeat',
    //     }} />
    //
    //   </Grid>


  );
}

export default VanliferOverview;
