import React, { useState } from 'react';
import { Button, Checkbox, Typography } from '@mui/material';


const MoreOptionsComponent = () => {
  const [selectedOptions, setSelectedOptions] = useState({
    Hotels: false,
    GasthausPension: false,
    Appartement: false,
    Campingplatz: true,
    WomoStellplatz: false,
    Parkplatz: false,
    Andere: false
  });

const handleSearchOptionsChange = (event: { target: { name: any; checked: any; }; }) => {
  const { name, checked } = event.target;
  setSelectedOptions({
    ...selectedOptions,
    [name]: checked
  });
};

return (
  <div style={{ padding: '20px', backgroundColor: 'primary.light'}}>
    <label>
      <Checkbox
        name="Hotels"
        checked={selectedOptions.Hotels}
        onChange={handleSearchOptionsChange}
      />
      Hotels
    </label>
    <br />
    <label>
      <Checkbox
        name="GasthausPension"
        checked={selectedOptions.GasthausPension}
        onChange={handleSearchOptionsChange}
      />
      Gasthaus/Pension
    </label>
    <br />
    <label>
      <Checkbox
        name="Appartement"
        checked={selectedOptions.Appartement}
        onChange={handleSearchOptionsChange}
      />
      Appartement
    </label>
    <br />
    <label>
      <Checkbox
        name="Campingplatz"
        checked={selectedOptions.Campingplatz}
        onChange={handleSearchOptionsChange}
      />
      Campingplatz
    </label>
    <br />
    <label>
      <Checkbox
        name="WomoStellplatz"
        checked={selectedOptions.WomoStellplatz}
        onChange={handleSearchOptionsChange}
      />
      Womo-Stellplatz
    </label>
    <br />
    <label>
      <Checkbox
        name="Parkplatz"
        checked={selectedOptions.Parkplatz}
        onChange={handleSearchOptionsChange}
      />
      Parkplatz
    </label>
    <br />
    <label>
      <Checkbox
        name="Andere"
        checked={selectedOptions.Andere}
        onChange={handleSearchOptionsChange}
      />
      Andere
    </label>
  </div>
);
};

// WeitereOptionen Komponente mit aufklappbarem Bereich
const SearchOptions = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div >
      <Button fullWidth variant="text" color={'primary'} onClick={toggleOptions}>
        {isOpen ? 'Weniger Optionen' : 'Weitere Optionen'}
      </Button>
      {isOpen && (
        <div style={{ marginTop: '10px' }}>
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            Bitte wähle die Arten von Unterkünften aus, die für Deine Reise in Frage kommen:
          </Typography>
          <MoreOptionsComponent />
        </div>
      )}
    </div>
  );
};

export default SearchOptions;