import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import imgSlider1 from '../../assets/Images/trip_planner.jpg';

import Container from '../Container';
import { PaletteMode } from '@mui/material';
import SearchTours from './SearchTours';
import {useAppSelector} from "../../hooks/hooks";

const SearchToursContainer = (): JSX.Element => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
    const [rightImage, setRightImage] = useState(imgSlider1.toString())

  const regions = useAppSelector(state => state.region.regions);
  const regionIdFromState = useAppSelector(state => state.vanliferComponents.searchFormData?.regionId);

    useEffect(() => {
        if (regions && regionIdFromState) {
            const region = regions.find(region => region.regionId === regionIdFromState);
            if (region && region.imageIds && region.imageIds.length > 0) {
                setRightImage(`${process.env.REACT_APP_API_URL as string}/image/${region.imageIds[0]}`);
            } else {
                setRightImage(imgSlider1.toString());
            }
        }
    }, [regionIdFromState, regions]);


    const LeftSide = () => (
    <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'text.secondary'}
      >
        Reiseplaner
      </Typography>
      <Box marginBottom={2}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontWeight: 700,
          }}
        >
          Hol Dir Deine {' '}
          <br />
          <Typography
            color={'text.primary'}
            component={'span'}
            variant={'inherit'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                0.3,
              )} 0%)`,
            }}
          >
            Reisevorschläge
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h6" component="p" color="text.secondary">
          Lass Dir Reisevorschläge machen. Individuell und speziell für Dich.
          <br />
          Gib deine Wunschdaten ein und wähle eine Region aus, die du bereisen möchtest.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        // gap={'1rem'}
      >
        <SearchTours />
      </Box>
    </Box>
  );

    const RightSide = () => {
        return (
            <Box
                sx={{
                    height: { xs: 'auto', md: 1 },
                    '& .slick-slide img': {
                        objectFit: 'cover',
                    },
                    '& .slick-list, & .slick-slider, & .slick-track, & .slick-slide > div': {
                        height: { xs: 'auto', md: 1 },
                    },
                }}
            >
                <Box
                    component={'img'}
                    loading="lazy"
                    src={rightImage}
                    height={{ xs: 'auto', md: 1 }}
                    maxHeight={{ xs: 500, md: 1 }}
                    style={{objectFit: 'cover'}}
                    width={1}
                    maxWidth={1}
                />
            </Box>
        );
    };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
          minHeight={{ xs: 'auto', md: 600 }}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                    clipPath: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 10% 100%, 0% 50%)',
                    },
                    shapeOutside: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%, 0% 50%)',
                    },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SearchToursContainer;
